// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 16px;
    margin: 0 auto;
    height: fit-content;
    min-height: 100vh;
    outline: none;
    font-size: 1rem;

    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.Heading{
    display: flex;
    align-items: center;
    justify-content: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,eAAe;;IAEf,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".Container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 70%;\r\n    margin-top: 16px;\r\n    margin: 0 auto;\r\n    height: fit-content;\r\n    min-height: 100vh;\r\n    outline: none;\r\n    font-size: 1rem;\r\n\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding-bottom: 30px;\r\n}\r\n\r\n.Heading{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
