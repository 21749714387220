import React from "react";
import { IoMdArrowBack } from "react-icons/io";

const ContactUs = () => {
  return (
    <div
      className="Container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div  style={{ display: "flex", width: '100%', marginTop: '10px'}}>
      <IoMdArrowBack size={30} onClick={() => window.history.back()}/>
      </div>
      <h1 className="Heading">CONTACT US:</h1>
      <p>Call / WhatsApp +91-9871075578</p>
      <p>Support/Feedback:  <a href="mailto:support@netse.in" style={{font:"inherit"}}>support@netse.in</a></p>
    </div>
  );
};


// href="mailto:support@netse.in"

export default ContactUs;
