// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-confirmation-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px 25px;
}

.order-details-section {
  margin-bottom: auto;
}

.icon-orderConfirmed {
  max-width: 150px;
  margin-bottom: 30px;
}

.icon-instructionsAdded {
  max-width: 30px;
  margin-right: 12px;
}

.instructionAdded {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 34px auto 26px;
}

.order-text {
  line-height: 25px;
}

.special-section {
  margin: auto;
}

.confirmed-caption  {
  font-size: 12px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
}

#btn-addSpecialInstructions, #btn-placeAnotherOrder {
  margin-top: 19px;
}

.refer-us-section {
  margin-top: auto;
}

#btn-referUs {
  margin-bottom: 11px;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderConfirmation/orderConfirmed.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".order-confirmation-wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  padding: 40px 25px;\n}\n\n.order-details-section {\n  margin-bottom: auto;\n}\n\n.icon-orderConfirmed {\n  max-width: 150px;\n  margin-bottom: 30px;\n}\n\n.icon-instructionsAdded {\n  max-width: 30px;\n  margin-right: 12px;\n}\n\n.instructionAdded {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 34px auto 26px;\n}\n\n.order-text {\n  line-height: 25px;\n}\n\n.special-section {\n  margin: auto;\n}\n\n.confirmed-caption  {\n  font-size: 12px;\n}\n\n.button-wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n#btn-addSpecialInstructions, #btn-placeAnotherOrder {\n  margin-top: 19px;\n}\n\n.refer-us-section {\n  margin-top: auto;\n}\n\n#btn-referUs {\n  margin-bottom: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
