import React from 'react'
import { IoMdArrowBack } from "react-icons/io";

const Terms = () => {
  return (
    <div className='Container'>
      <div  style={{ display: "flex", width: '100%', marginTop: '10px'}}>
      <IoMdArrowBack size={30} onClick={() => window.history.back()}/>
      </div>
      <h1 className='Heading'>Terms/Conditions</h1>
      <p>These terms and conditions (“terms”) govern your use of our website and services. By using our website or services, you agree to these terms and accept our privacy policy.
         Ordering and Delivery
</p>
      <ul><li>You can place an order with us through our website or WhatsApp at +91-9871075578.</li></ul>
      <ul><li>You can choose from a variety of fruits, vegetables, exotic vegetables, spices, and wood pressed oil products available on our website or WhatsApp.</li></ul>
      <ul><li>You can pay for your order online through Bank Transfer, Payments apps like Paytm, PhonePe, Gpay  or cash on delivery.</li></ul>
      <ul><li>We will confirm your order and delivery details through WhatsApp.</li></ul>
      <ul><li>We will deliver your order the next day, depending on the availability of the products and the delivery location.</li></ul>
      <ul><li><b>NO DELIVERY FEE </b> is charged unless there is change in the address the same day.</li></ul>
      <ul><li>We will try our best to deliver your order on time and in good condition. However, we are not liable for any delays or damages caused by factors beyond our control, such as traffic, weather, accidents, etc.</li></ul>
      <ul><li>We will try our best to deliver your order on time and in good condition. However, we are not liable for any delays or damages caused by factors beyond our control, such as traffic, weather, accidents, etc.</li></ul>
      <ul><li>You can return or exchange any product that is defective, damaged, or not as described within 24 hours of receiving it by contacting us through WhatsApp. We will replace it with a new product or refund the amount accordingly.</li></ul>
   
     <h2 className='Heading'>Quality and Price</h2>
      <ul><li>We hand pick every item from the wholesale markets and deliver them to you at the best prices</li></ul>
      <ul><li>We ensure that you receive fresh and quality products every day.</li></ul>
      <ul><li>We update our prices regularly based on the market rates and availability of the products.</li></ul>
      <ul><li>We display the prices of the products on our website and WhatsApp. However, the prices may vary slightly due to rounding off or human error. The final price will be confirmed at the time of order being dispatched.</li></ul>
   
   <h2 className='Heading'>Communication and Promotion</h2>

   <ul><li>We are always live on WhatsApp at +91-9871075578 to help you with any queries, feedbacks, or complaints.</li></ul>
   <ul><li>You can also email us at <a href="mailto:support@netse.in" style={{font:"inherit"}}>support@netse.in</a> for any suggestions or issues.</li></ul>
   <ul><li>We may send you promotional offers and updates about our products and services through WhatsApp or email, if you opt-in to receive them.</li></ul>
   <ul><li>You can opt-out of receiving such communications at any time by contacting us through WhatsApp or email.</li></ul>
   
   <h2 className='Heading'>Disclaimer and Limitation of Liability</h2>
   <ul><li>We provide our website and services “as is” and “as available” without any warranties of any kind, express or implied.</li></ul>
   <ul><li>We do not warrant that our website and services will be uninterrupted, error-free, secure, accurate, reliable, or suitable for your needs.</li></ul>
   <ul><li>We are not responsible for any loss or damage arising from your use of our website and services or reliance on any information provided by us.</li></ul>
   <ul><li>Our liability for any claim arising from your use of our website and services is limited to the amount paid by you for the relevant product or service.</li></ul>

   <h2 className='Heading'>Changes to these Terms</h2>
   <p>We may change these terms at any time by posting the updated version on our website and notifying you through WhatsApp or Email. Your continued use of our website and services after such changes constitutes your acceptance of the new terms.
      Governing Law and Jurisdiction
      These terms are governed by and construed in accordance with the laws of India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of Delhi.
</p>
<b>Contact Us:</b>
<b>Email ID:          <a href="mailto:support@netse.in" style={{font:"inherit"}}>support@netse.in</a></b>
<b>WhatsApp Helpline:     +91-9871075578 </b>


    </div>
  
  )
}

export default Terms