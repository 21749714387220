import React from 'react'
import { NavLink } from "react-router-dom";
import { useFooterVisibility } from "../hooks";

const Footer = () => {
    const { showFooter } = useFooterVisibility((state) => state);
    return showFooter ? <footer id="footer">
        <NavLink exact to="/about-us">
            About Us
        </NavLink>
        <NavLink exact to="/contact-us">

            Contact Us
        </NavLink>
        <NavLink exact to="/privacy-policy">

            Privacy Policy
        </NavLink>
        <NavLink exact to="/terms-and-conditions">

            Terms & Conditions
        </NavLink>
    </footer> : <></>
}

export default Footer;