// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background-rect.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery-wrapper {
  margin: 8px 14px;
}

.delivery-wrapper .delivery-container {
  background: #E9E9E9;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7px 12px;
  border-radius: 3px;
}

.delivery-wrapper .delivery-container .row { 
  display: flex;
  flex-direction: row;
  align-items: center;
}

.delivery-wrapper .delivery-container .row.secondRow {
  margin-bottom: 5px;
}

.delivery-wrapper .delivery-container .row .column {
  width: 50%;
}

.delivery-wrapper .delivery-container .row .column.boldColumn {
  font-weight: bold;
  font-size: 14px;
}

.dayInfo {
  color: #04A851;
}

.popup-whatsapp {
  text-align: center;
}

.popup-whatsapp-caption {
  text-align: left;
}

.btn-whatsapp {
  display: flex;
  align-items: center;
  max-width: 270px;
  margin: 0 auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  text-decoration: none;
  font-size: 13px;
}

.btn-whatsapp img {
  margin: 0;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/DeliveryComponent/deliveryComponent.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,yDAAyD;EACzD,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".delivery-wrapper {\n  margin: 8px 14px;\n}\n\n.delivery-wrapper .delivery-container {\n  background: #E9E9E9;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 7px 12px;\n  border-radius: 3px;\n}\n\n.delivery-wrapper .delivery-container .row { \n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.delivery-wrapper .delivery-container .row.secondRow {\n  margin-bottom: 5px;\n}\n\n.delivery-wrapper .delivery-container .row .column {\n  width: 50%;\n}\n\n.delivery-wrapper .delivery-container .row .column.boldColumn {\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.dayInfo {\n  color: #04A851;\n}\n\n.popup-whatsapp {\n  text-align: center;\n}\n\n.popup-whatsapp-caption {\n  text-align: left;\n}\n\n.btn-whatsapp {\n  display: flex;\n  align-items: center;\n  max-width: 270px;\n  margin: 0 auto;\n  background-image: url(\"../../assets/background-rect.png\");\n  text-decoration: none;\n  font-size: 13px;\n}\n\n.btn-whatsapp img {\n  margin: 0;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
