// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-responsive-modal-modal {
    width: 600px;
}


.product-list-heading {
    border-bottom: 0px; 
    padding-bottom: 0px;
    margin-bottom: 0px;
}

@media screen and (max-width:768px) {
    .react-responsive-modal-modal {
        width: calc(100% - 30px);
        margin-top:33%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/OrderEdit/OrderEdit.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;;AAGA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,wBAAwB;QACxB,cAAc;IAClB;AACJ","sourcesContent":[".react-responsive-modal-modal {\n    width: 600px;\n}\n\n\n.product-list-heading {\n    border-bottom: 0px; \n    padding-bottom: 0px;\n    margin-bottom: 0px;\n}\n\n@media screen and (max-width:768px) {\n    .react-responsive-modal-modal {\n        width: calc(100% - 30px);\n        margin-top:33%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
