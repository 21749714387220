import React, { useState } from "react";
import moment from 'moment';
import CorrectImage from "../../assets/correct.png";
import "./orderConfirmed.css";
import SpecialInstructions from "./SpecialInstructions";
import ModalComponent from "../_generic/ModalComponent/ModalComponent";
import ProductService from '../../services/_product.service';
import { useAuth } from "../../context/auth";
import {
  useFooterVisibility
} from "../../shared/hooks";

function OrderConfirmation(props) {
  const [openModal, setOpenModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const { setFooterVisibility } = useFooterVisibility((state) => state);
  const [isSpecialInstructionsAdded, setisSpecialInstructionsAdded] = useState(
    false
  );
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const orderId = props.match.params.orderId;

  const { user } = useAuth();

  const userDetails = JSON.parse(user);

  const slotTime = userDetails?.address?.areaDetails?.slotDetails?.slot;
  const [deliveryDate, setDeliveryDate] = useState('');

  React.useEffect(() => {
    if (!slotTime) {
      ProductService.getOrder(orderId).then(res => {
        if (res.success) {
          const order = res.data;
          setOrderNumber(order.orderNumber);
          const deliveryDate = moment(order.deliveryDate).format('LL');
          setDeliveryDate(deliveryDate);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  });

  React.useEffect(() => {
    setFooterVisibility(false);
    return () => {
      setFooterVisibility(true);
    }
  }, [])

  const handleAddSpecialInstructions = async (modalResponse) => {
    setOpenModal(!modalResponse.closeModal)

    const response = await ProductService.addSpecialInstructions(orderId, modalResponse.value.specialInstructions);

    if (response.success) {
      setisSpecialInstructionsAdded(true);
    } else {

    }
  };

  return (
    <section className="order-confirmation-wrapper">
      <div className="order-details-section">
        <img
          className="icon-orderConfirmed"
          src={CorrectImage}
          alt="Order Confirmed"
        />
        <div className="order-text">
          Your order is scheduled to be delivered between <br />
          <strong>{slotTime} on {deliveryDate}</strong><br />
          <p><b>Order Number: {orderNumber}</b></p>
        </div>
      </div>
      <div className="special-section">
        <div className="button-wrapper">
          {isSpecialInstructionsAdded ? (
            <div className="instructionAdded">
              <img
                className="icon-instructionsAdded"
                src={CorrectImage}
                alt="Instructions Added"
              />
              Instructions added
            </div>
          ) : (
            <button
              id="btn-addSpecialInstructions"
              className="btn btn-inverted btn-inverted-bordered"
              onClick={() => handleModal()}
            >
              Add Special Instructions
            </button>
          )}

          <button id="btn-placeAnotherOrder" className="btn btn-dark" onClick={() => { window.location.href = "/place-orders/order" }}>
            Place another order
          </button>
        </div>
      </div>
      {/* <div className="refer-us-section">
        <button id="btn-referUs" className="btn btn-dark">
          Refer Us
        </button>
        <div className="confirmed-caption">
          and Get `300 discount when they place an order
        </div>
      </div> */}

      <ModalComponent
        component={SpecialInstructions}
        showModal={openModal}
        onClose={handleModal}
        submitToParent={handleAddSpecialInstructions}
      />
    </section>
  );
}

export default OrderConfirmation;
