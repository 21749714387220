import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import "./About.css";

const AboutUs = () => {
  return (
    <div className="Container">
       <div  style={{ display: "flex", width: '100%', marginTop: '10px'}}>
      <IoMdArrowBack size={30} onClick={() => window.history.back()}/>
      </div>
      <h1
        className="Heading"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        About Us
      </h1>
      <h3 style={{margin:0}}>1st One:</h3>
      <p>
        NETSE is a startup that delivers fresh and quality fruits, vegetables,
        exotic vegetables, premium spices, and self-manufactured wood pressed
        oil in South Delhi, Central Delhi, and NCR. We are committed to
        providing our customers with the best produce at the lowest prices. We
        are always live on <b> WhatsApp +91-9871075578 </b> to help our
        customers with any queries, feedbacks, or complaints. You can even place
        your order on WhatsApp, something that no e-commerce platform ever
        offers.
      </p>

      <h3 className="Heading">How do we do it?</h3>
      <p>
        Unlike other e-commerce platforms that have their produce delivered to
        them at their warehouses, we have our experts handpick every item from
        the wholesale markets like Azadpur, Okhla, Gazipur, Hapur, Sohna &
        Chandigarh and then bring it to our warehouses. This helps us guarantee
        that you receive fresh quality every day. This also helps us get lower
        prices which we pass on to our customers. Compare it. You will be
        shocked! Fruits and vegetables are up to 20-25% OFF. Exotics up to 50%
        OFF. TRY IT TO KNOW IT.
      </p>

      <h3 className="Heading">What else do we offer?</h3>
      <p>
        We also deliver premium spices and self-manufactured wood pressed oil
        that are good for your health and skin. Our spices are sourced from the
        finest farms and processed with utmost care. Our wood pressed oil is
        made from natural ingredients and free from any chemicals or
        preservatives. You can choose from a variety of oils such as mustard,
        coconut, sesame, groundnut, and more.
      </p>

      <h3 className="Heading">Why choose us?</h3>
      <p>
        We are not just another e-commerce company. We are a team of passionate
        people who love what we do and care about our customers. We believe in
        delivering quality, freshness, and convenience at your doorstep. We are
        always ready to listen to your feedback and suggestions and improve our
        services accordingly. We are NETSE - your one-stop destination for all
        your fruits, vegetables, exotic vegetables, spices, and oil needs. We
        are NETSE, and we are here to serve you better. Join us today and
        experience the difference
      </p>
    </div>
  );
};

export default AboutUs;
