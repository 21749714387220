// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-search {
  width: 100%;
}

.input-box {
  margin-left: 15px;

  height: 40px;
  background: #ececec;
  border: none;
  outline: none;
  border-radius: 5px;
  flex: 1 1;
  padding: 5px 15px;
  border: 1px solid #cccc;
}

.textarea-box {
  margin-left: 15px;
  background: #ececec;
  border: none;
  outline: none;
  border-radius: 5px;
  flex: 1 1;
  min-height: 100px;
  border: 1px solid #cccc;
  padding: 15px;
  font-size: 14px;
  font-family: "Arial", sans-serif;
}

@media (max-width: 300px) {
  .input-box,
  .textarea-box {
    width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UserAddress/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;;EAEjB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,SAAO;EACP,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,SAAO;EACP,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE;;IAEE,YAAY;EACd;AACF","sourcesContent":[".select-search {\n  width: 100%;\n}\n\n.input-box {\n  margin-left: 15px;\n\n  height: 40px;\n  background: #ececec;\n  border: none;\n  outline: none;\n  border-radius: 5px;\n  flex: 1;\n  padding: 5px 15px;\n  border: 1px solid #cccc;\n}\n\n.textarea-box {\n  margin-left: 15px;\n  background: #ececec;\n  border: none;\n  outline: none;\n  border-radius: 5px;\n  flex: 1;\n  min-height: 100px;\n  border: 1px solid #cccc;\n  padding: 15px;\n  font-size: 14px;\n  font-family: \"Arial\", sans-serif;\n}\n\n@media (max-width: 300px) {\n  .input-box,\n  .textarea-box {\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
