// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background-rect.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100%;
  display: flex;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  padding: 5px 12px 0 12px; 
  align-items: center;
}

.header-container a {
  text-decoration: none;
}
.header-container .logo-text {
  margin-left: 8px;
  color: #fff;
  font-size: 27px;
  margin-top: -7px;
  text-decoration: none;
}

.header-container .right {
  display: flex;
  align-items: center;
}

.header-container .btn-logout, .header-container a.btn-previous-order {
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  margin-left: auto;
  /* letter-spacing: 1px; */
}

.header-container .btn-logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
}

.header-container a.btn-previous-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-bottom: none;
  margin-right: 20px;
}

.btn-previous-order img, .btn-logout img {
  margin-right: 10px;
}

.logo img {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yDAAyD;EACzD,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".header-container {\n  width: 100%;\n  display: flex;\n  background-image: url(\"../../assets/background-rect.png\");\n  background-size: cover;\n  padding: 5px 12px 0 12px; \n  align-items: center;\n}\n\n.header-container a {\n  text-decoration: none;\n}\n.header-container .logo-text {\n  margin-left: 8px;\n  color: #fff;\n  font-size: 27px;\n  margin-top: -7px;\n  text-decoration: none;\n}\n\n.header-container .right {\n  display: flex;\n  align-items: center;\n}\n\n.header-container .btn-logout, .header-container a.btn-previous-order {\n  font-size: 15px;\n  color: #fff;\n  font-weight: bold;\n  margin-left: auto;\n  /* letter-spacing: 1px; */\n}\n\n.header-container .btn-logout {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.header-container a.btn-previous-order {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  border-bottom: none;\n  margin-right: 20px;\n}\n\n.btn-previous-order img, .btn-logout img {\n  margin-right: 10px;\n}\n\n.logo img {\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
