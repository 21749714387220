// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.special-instructions-wrapper .heading {
  line-height: 24px;
  text-align: left;
  margin-bottom: 4px;
}

.content-container {
  height: 40%;
  margin: 12px 0px;
}
.content-container .netse-textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: #E9E9E9;
  border-radius: 6px;
  border: 1px solid #979797;
  padding: 10px;
  outline: none;
}

#btn-submitSpecialInstructions {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderConfirmation/specialInstruction.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":[".special-instructions-wrapper .heading {\n  line-height: 24px;\n  text-align: left;\n  margin-bottom: 4px;\n}\n\n.content-container {\n  height: 40%;\n  margin: 12px 0px;\n}\n.content-container .netse-textarea {\n  position: relative;\n  display: block;\n  width: 100%;\n  height: 100%;\n  min-height: 250px;\n  background: #E9E9E9;\n  border-radius: 6px;\n  border: 1px solid #979797;\n  padding: 10px;\n  outline: none;\n}\n\n#btn-submitSpecialInstructions {\n  float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
