import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="Container">
       <div  style={{ display: "flex", width: '100%', marginTop: '10px'}}>
      <IoMdArrowBack size={30} onClick={() => window.history.back()}/>
      </div>
      <h1>Privacy Policy:</h1>
      <p>
        This privacy policy explains how NETSE (“we”, “us”, or “our”) collects,
        uses, and protects your personal information when you visit our website
        or use our services. By using our website or services, you agree to this
        privacy policy and consent to our collection and use of your personal
        information as described below. What information do we collect? We only
        collect the following information from you when you place an order with
        us or contact us through WhatsApp:
      </p>
      <ul>
        <li>Your phone/WhatsApp number</li>
        <li>Your WhatsApp chat with us</li>
        <li>Your address</li>
        <li>Your address</li>
        <li>Your order History</li>
      </ul>
      <p>
        We do not collect any other information from you, such as your email,
        credit/debit card details, or any other sensitive data. How do we use
        your information? We use your information for the following purposes:
      </p>
      <ul>
        <li>To process and deliver your order</li>
        <li>
          To communicate with you about your order status, delivery details,
          feedback, or complaints
        </li>
        <li>To improve our website and services based on your feedback</li>
        <li>
          To send you promotional offers and updates about our products and
          services, if you opt-in to receive them
        </li>
      </ul>

      <p>
        How do we protect your information? We take reasonable measures to
        protect your information from unauthorized access, use, disclosure,
        modification, or destruction. We use secure servers and encryption
        technologies to store and transmit your information. We do not share
        your information with any third parties, except as required by law or to
        fulfill your order. How can you access and update your information? You
        can access and update your information by contacting us through WhatsApp
        at +91-9871075578. You can also request us to delete your information
        from our records, if you no longer want to use our services or receive
        our communications. How can you contact us? If you have any questions or
        concerns about this privacy policy or our practices, please contact us
        through WhatsApp at +91-9871075578 or email us at{" "}
        <a href="mailto:support@netse.in" style={{ font: "inherit" }}>
          support@netse.in.
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
