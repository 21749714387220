// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound-wrapper {
  text-align: center;
  margin: 22px 60px;
}

.notFound-wrapper .image-container {
  margin-bottom: 38px;
}

.notFound-wrapper .notFound-content-container .heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.notFound-wrapper .notFound-content-container .content {
  font-size: 16px;
  line-height: 19px;
}

.btn-rateList {
  text-decoration: none;
  min-width: 268px;
  display: inline-block;
}

`, "",{"version":3,"sources":["webpack://./src/components/NotFoundUser/notFoundUser.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".notFound-wrapper {\n  text-align: center;\n  margin: 22px 60px;\n}\n\n.notFound-wrapper .image-container {\n  margin-bottom: 38px;\n}\n\n.notFound-wrapper .notFound-content-container .heading {\n  font-size: 20px;\n  line-height: 24px;\n  font-weight: 700;\n  margin-bottom: 15px;\n}\n\n.notFound-wrapper .notFound-content-container .content {\n  font-size: 16px;\n  line-height: 19px;\n}\n\n.btn-rateList {\n  text-decoration: none;\n  min-width: 268px;\n  display: inline-block;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
