import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "./deliveryComponent.css";
import ModalComponent from "../_generic/ModalComponent/ModalComponent";
import { useAuth } from '../../context/auth';
import DeliveryDateService from '../../services/_deliveryDate.service';
import WhatsappIcon from '../../assets/icons/whatsapp.png'
import { useLoginUserDetails } from "../../shared/hooks";
import styled from "styled-components";

const ChangeDeliveryComponent = () => (
  <div className="popup-whatsapp">
    <div className="popup-whatsapp-caption">For changes to account, please contact us on Whatsapp (9871075578)</div>
    <br />
    <a href="whatsapp://send?phone=+919871075578" className="btn btn-whatsapp"><img src={WhatsappIcon} alt="whatsapp" /> Contact us on Whatsapp</a>
  </div>
);

function DeliveryDetails() {

  const { setLoginUserDetails } = useLoginUserDetails((state) => state);

  const [openModal, setOpenModal] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState();

  const [isDeliveryTomorrow, setIsDeliveryTomorrow] = useState(false);

  const [isDeliveryDateSet, SetIsDeliveryDateSet] = useState(false);

  const [showDetailedInfo, SetShowDetailedInfo] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleDeliveryDate = async () => {
    const response = await DeliveryDateService.getDeliveryDate();
    if (response.success) {
      const deliveryDate = response.data.deliveryDate;
      setDeliveryDate(deliveryDate);

      if (moment().utcOffset('+05:30').add(1, 'day').format('YYYY-MM-DD') === deliveryDate) {
        setIsDeliveryTomorrow(true);
      }
    }
  };

  const handleShowHide = () => {
    SetShowDetailedInfo(!showDetailedInfo)
  }

  const { user: UserDetails } = useAuth();
  const user = JSON.parse(UserDetails);

  if (!isDeliveryDateSet) {
    handleDeliveryDate();
    SetIsDeliveryDateSet(true);
  }



  return (
    <div className="delivery-wrapper">
      <div className="delivery-container">
        {
          !showDetailedInfo ?
            <div className="row" onClick={handleShowHide}>
              <div className="column">Estimated Delivery:</div>
              <div className="column boldColumn">
                <div className="timeSlot">
                  {user?.address?.areaDetails?.slotDetails?.slot} {`${user?.address?.areaDetails?.slotDetails?.slot ? '' : ''}`}{" "} <span className="dayInfo">{isDeliveryTomorrow ? "Tomorrow" : ""}</span>
                </div>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
            </div>
            : null
        }
        {
          showDetailedInfo ?
            <>
              <div className="row" style={{ marginBottom: '10px' }} onClick={handleShowHide}>
                <div className="column">
                  Delivery Address:
                  <br />{" "}
                  <span>
                    <Link
                      to="/address"
                      className="right"
                    >
                      Change
                    </Link>
                  </span>{" "}
                </div>
                <div style={{ marginLeft: '15px' }} className="column boldColumn">
                  {user?.address?.address1}
                  <br />
                  {user?.address?.areaDetails?.name}
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                  </svg>
                </div>
              </div>
              <div className="row secondRow">
                <div className="column">Estimated Delivery:</div>
                <div className="column boldColumn">
                  <div className="timeSlot">
                    {user?.address?.areaDetails?.slotDetails?.slot},{" "}

                  </div>
                  <div className="daySlot">
                    <span className="dayInfo">
                      {isDeliveryTomorrow ? "Tomorrow" : ""}  &nbsp;
                    </span>
                    ({moment(deliveryDate).utcOffset('+05:30').format("MMM, DD")})</div>
                </div>
              </div>
            </>
            : null
        }
      </div>

      <ModalComponent
        component={ChangeDeliveryComponent}
        showModal={openModal}
        onClose={handleModal}
      />
    </div>
  );
}

export default DeliveryDetails;


const SearchIconSvg = styled.svg`
  color: #a5a5a5;
  fill: currentColor;
  padding: 10px;
  width: 44px;
  height: 44px;
`;