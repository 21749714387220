import React from "react";
import { useFormik } from "formik";
import cookie from "react-cookies";
import styled from "styled-components";
import { toast } from "react-toastify";
import "./style.css";
import * as Yup from "yup";
import { RestApiService } from "../../services/rest-api.service";
import { ApiEndpoint } from "../../services/api-endpoint";
import { useAuth } from "../../context/auth";
import { useHistory } from "react-router-dom";
import { useLoginUserDetails, useLoader } from "../../shared/hooks";
import { SERVER_ERROR } from "../../core/String";
import { IoIosArrowDown } from "react-icons/io";
import "./style.css";

const UserAddress = (props) => {
  const history = useHistory();
  const restAPIService = new RestApiService();
  const [isZipCodeValid, setIsZipCodeValid] = React.useState(false);
  const [isAreaValid, setIsAreaValid] = React.useState(false);
  const [isAreaButtonVisible, setIsAreaButtonVisible] = React.useState(true);
  const [hasValidateArea, setHasValidateArea] = React.useState(false);
  const [customArea, setCustomArea] = React.useState("");
  const [onlyCustomArea, setOnlyCustomArea] = React.useState(false);
  const [areaList, setAreaList] = React.useState([]);
  const { details, setLoginUserDetails } = useLoginUserDetails(
    (state) => state
  );
  const { setLoader } = useLoader((state) => state);
  const [initialValue, setInitialValue] = React.useState({
    phoneNumber: details.phoneNumber,
    zipid: "",
    zipcodeDetails: { Zipcode: "" },
    name: "",
    address: { address1: "", areaId: "" },
  });
  let { user } = useAuth();
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Phone is required"),
    zipcodeDetails: Yup.object({
      Zipcode: Yup.string()
        .required("Zipcode is required")
        .matches(/^[1-9][0-9]{5}$/, "Please enter valid zipcode"),
    }),
    address: Yup.object({
      address1: Yup.string().required("Address is required"),
      areaId: Yup.string().required("Area is required"),
    }),
  });

  React.useEffect(() => {
    const userDetails = JSON.parse(user);
    if (userDetails && userDetails?._id) {
      getUserDetails(userDetails._id);
    }
  }, [user]);

  const validatePincode = (zipcode) => {
    setLoader(true);
    restAPIService.invoke(ApiEndpoint.VALIDATE_PINCODE, { code: zipcode }).then(
      (res) => {
        setLoader(false);
        if (res.data.data) {
          const { id } = res.data.data;
          setIsZipCodeValid(true);
          addressForm.setFieldValue("zipid", id);
          getAreaList(res.data.data.id);
        } else {
          createPinCode(zipcode);
        }
      },
      (error) => {
        setLoader(false);
        console.log("hello error", error);
        createPinCode(zipcode);
        // toast.error(SERVER_ERROR.message);
      }
    );
  };

  const createPinCode = (pincode) => {
    setLoader(true);

    const { phoneNumber, AreaName } = addressForm.values;
    restAPIService
      .invoke(ApiEndpoint.CREATE_INTERESTED_USER, null, {
        id: details._id,
        Zipcode: pincode,
        area: AreaName,
        phoneNumber,
      })
      .then(
        (res) => {
          setLoader(false);
          const data = res.data.body;
          setIsZipCodeValid(false);
          setLoginUserDetails({ phoneNumber, id: res.data.body._id });
          if (data) {
            const { _id } = data;
            addressForm.setFieldValue("zipid", _id);
            setOnlyCustomArea(true);
          }
        },
        (error) => {
          setLoader(false);
          setIsZipCodeValid(false);
          toast.error(SERVER_ERROR.message);
        }
      );
  };

  const createAreaForZipCode = (area) => {
    setLoader(true);
    const {
      zipcodeDetails: { Zipcode },
      phoneNumber,
    } = addressForm.values;
    restAPIService
      .invoke(ApiEndpoint.CREATE_INTERESTED_USER, null, {
        id: details.id,
        Zipcode,
        phoneNumber,
        area,
      })
      .then(
        (res) => {
          setLoader(false);
          if (res.data.body) {
            setIsAreaValid(false);
            setHasValidateArea(true);
            setIsZipCodeValid(false);
            setOnlyCustomArea(true);
            setIsAreaButtonVisible(false);
            setLoginUserDetails({ phoneNumber, id: res.data.body._id });
          }
        },
        (error) => {
          setLoader(false);
          toast.error(SERVER_ERROR.message);
        }
      );
  };

  const addressForm = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialErrors: {
      zipcodeDetails: {
        Zipcode: true,
      },
    },
    onsubmit: () => {},
  });

  const getUserDetails = (userId) => {
    setLoader(true);
    restAPIService
      .invoke(ApiEndpoint.GET_USER_DETAILS, { userId })
      .then((res) => {
        setLoader(false);
        const { zipid } = res.data.data;
        setInitialValue(res.data.data);
        setIsAreaValid(true);
        setIsZipCodeValid(true);
        setHasValidateArea(true);
        getAreaList(zipid);
      })
      .catch((error) => {
        setLoader(false);
        console.log("get error", error);
      });
  };

  const getAreaList = (zipId) => {
    setLoader(true);
    restAPIService
      .invoke(ApiEndpoint.GET_AREA_BY_ZIP_ID, { id: zipId })
      .then((res) => {
        const areas = res.data.data.map((elem) => ({
          name: elem.name,
          id: elem.id,
        }));
        areas.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        areas.push({ name: "Other", id: "0" });
        setAreaList(areas);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("get error", error);
      });
  };

  const createUser = (data) => {
    setLoader(true);
    const userDetails = (typeof user === 'string') ? JSON.parse(user) : user;
    const apiEndPoint = userDetails?._id
      ? ApiEndpoint.UPDATE_USER
      : ApiEndpoint.CREATE_USER;
    restAPIService
      .invoke(
        apiEndPoint,
        { userId: userDetails?._id },
        {
          ...data,
        }
      )
      .then(
        (res) => {
          toast.success(
            `User ${userDetails?._id ? "updated" : "created"} Successfully`
          );
          history.push("/order");
          localStorage.setItem("user", JSON.stringify(res.data.data));
          cookie.save("user", JSON.stringify(res.data.data), { path: "/" });
          window.location.reload();
          setLoader(false);
        },
        (error) => {
          setLoader(false);
          toast.error("Unable to create User");
        }
      );
  };

  const onValidateArea = () => {
    if (customArea) {
      createAreaForZipCode(customArea);
    } else {
      setHasValidateArea(true);
    }
  };

  const onChangeZipCode = () => {
    addressForm.setFieldValue("zipid", "");
    addressForm.setFieldValue("zipcodeDetails.Zipcode", "");
    addressForm.setFieldValue("address.areaId", "");
    setIsAreaValid(false);
    setIsZipCodeValid(false);
    setHasValidateArea(false);
    setIsAreaButtonVisible(true);
    setOnlyCustomArea(false);
    setCustomArea("");
    setAreaList([]);
  };

  return (
    <React.Fragment>
      <Div className="zipcode_form">
        {
          <>
            <H6>Please provide your delivery address</H6>
            <Form autoComplete="off">
              <Label>
                <Span>Phone No:</Span>
                <InputContainer>
                  <Input1
                    disabled={true}
                    type="text"
                    name="Phone"
                    autoComplete="off"
                    value={addressForm.values.phoneNumber}
                  />
                  {/* <Change onClick={() => history.goBack()}>Change</Change> */}
                </InputContainer>
              </Label>
              <Label>
                <Span>Pincode:</Span>
                <InputContainer>
                  <Input
                    style={{ width: "50%" }}
                    type="number"
                    pattern="\d*"
                    disabled={addressForm.values?.zipid}
                    value={addressForm.values?.zipcodeDetails?.Zipcode}
                    autoComplete="off"
                    onChange={(event) =>
                      addressForm.setFieldValue(
                        "zipcodeDetails.Zipcode",
                        event.target.value
                      )
                    }
                  />
                  {addressForm.values.zipid && (
                    <Change onClick={onChangeZipCode}>Change</Change>
                  )}
                </InputContainer>
              </Label>

              {addressForm.values?.zipid && areaList.length > 0 && (
                <Label>
                  <Span>Area:</Span>
                  <InputContainer
                    style={{
                      position: "relative",
                    }}
                  >
                    <Select
                      name="area"
                      value={addressForm.values?.address?.areaId}
                      onChange={(event) => {
                        setIsAreaValid(true);
                        setIsAreaButtonVisible(true);
                        addressForm.setFieldValue(
                          "address.areaId",
                          event.target.value
                        );
                        if (event.target.value !== "0") {
                          setHasValidateArea(true);
                        }
                      }}
                    >
                      <option>Select Area</option>
                      {areaList.map((elem) => (
                        <option value={elem.id} key={elem.id}>
                          {elem.name}
                        </option>
                      ))}
                    </Select>
                    <IoIosArrowDown
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "10px",
                      }}
                    />
                  </InputContainer>
                </Label>
              )}
              {addressForm.values?.zipid &&
                (addressForm.values.address.areaId === "0" ||
                  areaList.length === 0) && (
                  <Label>
                    <Span>Enter area:</Span>
                    <InputContainer>
                      <input
                        className="input-box"
                        type="text"
                        id="some_id"
                        autoComplete="new-password"
                        value={customArea}
                        onChange={(event) => setCustomArea(event.target.value)}
                      />
                    </InputContainer>
                  </Label>
                )}
              {(!isZipCodeValid ||
                !isAreaValid ||
                !hasValidateArea ||
                addressForm.values.address.areaId === "0") && (
                <RowContentLeft>
                  {!addressForm.values?.zipid && (
                    <ProceedButton
                      type="button"
                      disabled={addressForm?.errors?.zipcodeDetails?.Zipcode}
                      onClick={() =>
                        validatePincode(
                          addressForm.values.zipcodeDetails.Zipcode
                        )
                      }
                    >
                      Next
                    </ProceedButton>
                  )}
                  {isAreaButtonVisible &&
                    ((addressForm.values?.zipid && !hasValidateArea) ||
                      (addressForm.values.address.areaId === "0" &&
                        user?._id) ||
                      (addressForm.values.address.areaId === "0" &&
                        customArea)) && (
                      <ProceedButton
                        type="button"
                        disabled={
                          !addressForm?.values?.address?.areaId && !customArea
                        }
                        onClick={onValidateArea}
                      >
                        Next
                      </ProceedButton>
                    )}
                  {!(
                    addressForm.values?.zipid &&
                    hasValidateArea &&
                    (addressForm.values?.address?.areaId || customArea) &&
                    (!isZipCodeValid || !isAreaValid)
                  ) && (
                    <ButtonLink onClick={() => history.goBack()}>
                      Cancel
                    </ButtonLink>
                  )}
                </RowContentLeft>
              )}
            </Form>
            {addressForm.values?.zipid &&
              hasValidateArea &&
              (addressForm.values?.address?.areaId || customArea) &&
              (!isZipCodeValid || !isAreaValid) && (
                <>
                  <ErrorMsg>
                    We don't deliver to this area yet. We have noted your
                    interest and would contact you as soon as we deliver in your
                    area.
                  </ErrorMsg>
                  <RowContentCenter>
                    <TryAnother onClick={() => onChangeZipCode()}>
                      Try Another Pincode
                    </TryAnother>
                  </RowContentCenter>
                </>
              )}
          </>
        }
        {isAreaValid &&
          isZipCodeValid &&
          hasValidateArea &&
          addressForm.values.address.areaId !== "0" && (
            <React.Fragment>
              <Label>
                <Span>Full Name:</Span>
                <InputContainer>
                  <input
                    className="input-box"
                    type="text"
                    id="some_id"
                    autoComplete="new-password"
                    value={addressForm.values?.name}
                    onChange={(event) =>
                      addressForm.setFieldValue("name", event.target.value)
                    }
                  />
                </InputContainer>
              </Label>
              {/* <Label>
            <Span>Email:</Span>
            <InputContainer>
              <Input type="text" name="email" />
            </InputContainer>
          </Label> */}
              <Label>
                <Span>{`Complete\n Address:`}</Span>
                <InputContainer>
                  <textarea
                    className="textarea-box"
                    type="text"
                    id="some_id"
                    autoComplete="new-password"
                    value={addressForm.values?.address?.address1}
                    onChange={(event) =>
                      addressForm.setFieldValue(
                        "address.address1",
                        event.target.value
                      )
                    }
                  />
                </InputContainer>
              </Label>
              <RowContentLeft>
                <ProceedButton
                  disabled={Object.keys(addressForm.errors).length > 0}
                  type="button"
                  onClick={() => createUser(addressForm.values)}
                >
                  {user?._id ? "Update Address" : "Proceed to Place Order"}
                </ProceedButton>
                <ButtonLink onClick={() => history.goBack()}>Cancel</ButtonLink>
              </RowContentLeft>
            </React.Fragment>
          )}
      </Div>
    </React.Fragment>
  );
};

export default UserAddress;

const ButtonLink = styled.a`
  display: block;
  text-align: center;
  color: #1c5922;
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
  padding: 15px;
`;

const Div = styled.div`
  width: 100%;
  padding: 0px 12px;
  @media (max-width: 300px) {
    width: 150px;
  }
`;

const H6 = styled.h6`
  font-size: 15px;
  padding: 0px 0px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.label`
  display: flex;
  margin: 10px 0px;
  flex-direction: row;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-item: center;
  justify-content: space-between;
`;

const Change = styled.button`
  border: none;
  outline: none;
  background: none;
  color: blue;
  text-decoration: underline;
  margin-left: 10px;
`;
const Input = styled.input`
  margin-left: 15px;
  height: 40px;
  background: #ececec;
  border: none;
  outline: none;
  border-radius: 5px;
  flex: 1;
  padding: 5px 15px;
  border: 1px solid #cccc;
  @media (max-width: 300px) {
    width: 200px;
  }
`;

const Select = styled.select`
  margin-left: 15px;
  height: 40px;
  background: #ececec;
  border: none;
  outline: none;
  border-radius: 5px;
  flex: 1;
  padding: 5px 15px;
  border: 1px solid #cccc;
  -webkit-appearance: none;
  @media (max-width: 300px) {
    width: 200px;
  }
`;

const TextArea = styled.textarea`
  margin-left: 15px;
  background: #ececec;
  border: none;
  outline: none;
  border-radius: 5px;
  flex: 1;
  min-height: 100px;
  border: 1px solid #cccc;
  padding: 15px;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  @media (max-width: 300px) {
    width: 200px;
  }
`;
const Input1 = styled.input`
  margin-left: 15px;
  width: 100px;
  height: 40px;
  flex: 1;
  border: none;
  outline: none;
  border-radius: 5px;
  background: none;
`;

const ProceedButton = styled.button`
  width: auto;
  height: 40px;
  background-image: linear-gradient(to right, #17541e, #4f963d);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
`;

const TryAnother = styled.button`
  width: 250px;
  height: 40px;
  border: 1px solid #5ba544 !important;
  color: #326a35;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
`;

const ErrorMsg = styled.p`
  color: red;
  padding: 0px 15px;
  text-align: center;
`;
const RowContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 95px;
  margin-top: 20px;
`;
const RowContentCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 15px;
`;

const Span = styled.span`
  width: 80px;
`;
