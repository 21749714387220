// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-user-wrapper {
  margin: 8px 14px;
}

.user-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
}

.user-input-container .label {
  font-size: 14px;
}

.react-datepicker-popper {
  /* transform: translate3d(40%, 128px, 0px) !important; */
  left: -70px !important;
}

.customerPhoneNumber-wrapper {
  width: 47%;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminUserComponent/adminUserComponent.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wDAAwD;EACxD,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".admin-user-wrapper {\n  margin: 8px 14px;\n}\n\n.user-input-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  align-content: center;\n  margin-bottom: 15px;\n}\n\n.user-input-container .label {\n  font-size: 14px;\n}\n\n.react-datepicker-popper {\n  /* transform: translate3d(40%, 128px, 0px) !important; */\n  left: -70px !important;\n}\n\n.customerPhoneNumber-wrapper {\n  width: 47%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
