// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(151, 151, 151, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index:100;
}

.popup-container {
  position: relative;
  background: #fff;
  padding: 27px 24px;
  margin: auto 35px;
  line-height: 25px;
}

.btn-close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
}

.btn-close-popup img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/_generic/ModalComponent/modalComponent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mCAAmC;EACnC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,MAAM;EACN,OAAO;EACP,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".popup-wrapper {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  background: rgba(151, 151, 151, .5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  z-index:100;\n}\n\n.popup-container {\n  position: relative;\n  background: #fff;\n  padding: 27px 24px;\n  margin: auto 35px;\n  line-height: 25px;\n}\n\n.btn-close-popup {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n  width: 14px;\n}\n\n.btn-close-popup img {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
