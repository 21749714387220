import axios from 'axios';
import { DELIVERY_ENDPOINT } from '../helpers/api';

class DeliveryDateService {
  getDeliveryDate() {
    return new Promise(async (resolve, reject)=> {
      let response;
      try {
        response = await axios.get(DELIVERY_ENDPOINT.getDeliveryDate);
        resolve(response?.data);
      } catch(error) {
        resolve(error?.response?.data);
      }
    });
  }
}

export default new DeliveryDateService();