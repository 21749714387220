// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound-container {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/NotFoundPage/notFoundPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".notFound-container {\n  width: 100%;\n  height: calc(100vh - 100px);\n  display: flex;\n  align-items: center;\n  justify-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
